import { DefaultPageLayout } from 'routes/components/Layout'
import {
  LandingBadges,
  LandingBestSupport,
  LandingCompanies,
  LandingDevelopment,
  LandingHero,
  LandingIDE,
  LandingNetwork,
  LandingIntegrations,
  LandingUI,
  LandingInteraction,
  LandingInfrastructure,
} from './components'
import classes from './index.module.scss'

export default function IndexPage({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return (
    <DefaultPageLayout className={classes.page}>
      <div className='container'>
        <LandingHero />
        <LandingBadges />
        <LandingCompanies />
        <LandingNetwork totalIntegrations={totalIntegrations} />
        <LandingInteraction />
        <LandingIDE />
        <LandingIntegrations />
        <LandingUI />
        <LandingDevelopment />
        <LandingInfrastructure />
        <LandingBestSupport />
      </div>
    </DefaultPageLayout>
  )
}
